import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  checkEmail,
  completeProfile,
  getMe,
  forgotUserPassword,
  otp,
  password,
  setPassword,
  TCompleteProfile,
  ResetUserPassword,
  updateUserProfile,
  googleAuth,
  addToWishlist,
  removeToWishlist,
  getWishlist,
} from "../../../api/user.api";
import { AsyncState, retrieveErrorResponse } from "../../../utils";
import { ApiException, PrincipalResponse } from "../../../types";
import { principalStorageKey, tokenkey } from "../../../constants";

type UserPayload = PrincipalResponse;

// Parse the stored JSON string to an object or default to null if not found
const initialState: AsyncState<UserPayload, ApiException> = {
  loading: false,
  payload: {},
};

const googleSignInSignUp = createAsyncThunk(
  "user/google-auth",
  async (_, thunkApi) => {
    try {
      // Perform Google OAuth authentication logic here
      // Assuming you have a function called `googleAuth` that handles the OAuth flow
      const user = await googleAuth();
      return user;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const checkUserEmail = createAsyncThunk(
  "user/check-user-email",
  async (email: string, thunkApi) => {
    try {
      const info = await checkEmail(email);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);
const forgotPassword = createAsyncThunk(
  "user/forgot-password",
  async (email: string, thunkApi) => {
    try {
      const info = await forgotUserPassword(email);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const resetPassword = createAsyncThunk(
  "user/reset-password",
  async (
    data: {
      email: string;
      password: string;
      passwordConfirm: string;
      otp: string;
    },
    thunkApi
  ) => {
    try {
      const info = await ResetUserPassword(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const verifyOtp = createAsyncThunk(
  "user/otp",
  async (data: { email: string; otp: string }, thunkApi) => {
    try {
      const info = await otp(data.email, data.otp);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const verifyPassword = createAsyncThunk(
  "user/password",
  async (data: { email: string; password: string }, thunkApi) => {
    try {
      const info = await password(data.email, data.password);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);
const setUserPassword = createAsyncThunk(
  "user/set-password",
  async (
    data: { email: string; password: string; passwordConfirm: string },
    thunkApi
  ) => {
    try {
      const info = await setPassword(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const setCompleteProfile = createAsyncThunk(
  "user/complete-profile",
  async (data: TCompleteProfile, thunkApi) => {
    try {
      const info = await completeProfile(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);
const updateProfile = createAsyncThunk(
  "user/update-usr-profile",
  async (data: FormData, thunkApi) => {
    try {
      const info = await updateUserProfile(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (data: { token?: string } | undefined, thunkApi) => {
    try {
      const info = await getMe(data?.token);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addProgramToWishlist = createAsyncThunk(
  "user/addToWishlist",
  async (id: string, thunk) => {
    try {
      const info = await addToWishlist(id);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunk.rejectWithValue(error);
    }
  }
);
const removeProgramFromWishlist = createAsyncThunk(
  "user/removeProgramFromWishlist",
  async (id: string, thunk) => {
    try {
      const info = await removeToWishlist(id);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunk.rejectWithValue(error);
    }
  }
);

const getWishlistPrograms = createAsyncThunk(
  "user/getWishlist",
  async (_, thunk) => {
    try {
      const info = await getWishlist();
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunk.rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loadUserFromStorage: (state, action: PayloadAction<UserPayload>) => {
      state.payload = action.payload;
    },
    signout(state) {
      state.payload = {};
      state.loading = false;
      localStorage.removeItem(principalStorageKey);
      localStorage.removeItem(tokenkey);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(checkUserEmail.fulfilled, (state, action) => {
        // state.payload = action.payload;
        state.loading = false;
      })
      .addCase(checkUserEmail.pending, (state) => {
        state.payload = {};
        state.loading = true;
      })
      .addCase(checkUserEmail.rejected, (state, action) => {
        state.payload = {};
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(verifyPassword.fulfilled, (state, action) => {
        // state.payload = action.payload;
        state.loading = false;
        //dispatch getUserInfos
        // const dispatch = useAppDispatch();

        // dispatch(getUserInfo());
      })
      .addCase(verifyPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyPassword.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        // state.payload = action.payload;
        state.loading = false;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(setUserPassword.fulfilled, (state, action) => {
        // state.payload = action.payload;
        state.loading = false;
      })
      .addCase(setUserPassword.pending, (state, action) => {
        // state.payload = action.payload;
        state.loading = false;
      })
      .addCase(setUserPassword.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(setCompleteProfile.fulfilled, (state, action) => {
        // state.payload = action.payload;
        state.loading = false;
      })
      .addCase(setCompleteProfile.pending, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(setCompleteProfile.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.payload = {};
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.payload = {};
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(googleSignInSignUp.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(googleSignInSignUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(googleSignInSignUp.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.payload = {};
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.payload = {};
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(getWishlistPrograms.fulfilled, (state, action) => {
        state.payload = {
          ...state.payload,
          wishlist: action.payload.wishlist,
        };
        state.loading = false;
      })
      .addCase(getWishlistPrograms.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getWishlistPrograms.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(removeProgramFromWishlist.fulfilled, (state, action) => {
        state.payload = {
          ...state.payload,
          wishlist: action.payload.wishlist,
        };
        state.loading = false;
      })
      .addCase(removeProgramFromWishlist.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeProgramFromWishlist.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(addProgramToWishlist.fulfilled, (state, action) => {
        state.payload = {
          ...state.payload,
          wishlist: action.payload.wishlist,
        };
        // state.loading = false;
      })
      // .addCase(addProgramToWishlist.pending, (state) => {
      //   state.loading = true;
      // })
      .addCase(addProgramToWishlist.rejected, (state, action) => {
        // state.loading = false;
        state.errors = [action.payload as ApiException];
      });
  },
});

export const userActions = {
  ...authSlice.actions,
  checkEmail,
  otp,
  updateProfile,
  password,
  setCompleteProfile,
  getUserInfo,
  setUserPassword,
  verifyOtp,
  verifyPassword,
  checkUserEmail,
  forgotPassword,
  resetPassword,
  googleSignInSignUp,
  addProgramToWishlist,
  addToWishlist,
  removeProgramFromWishlist,
  getWishlistPrograms,
};
export default authSlice.reducer;
