export const principalStorageKey = "_liah_study_principal_storage";
export const tokenkey = "_tkn_key";

export const currentBackend = String(
  process.env.REACT_APP_BASE_URL_DEV ??
    "https://liah-edu-server.liahstudy.online"
);

export const emailkey = "__auth_mail";
export const googleCallback = String(
  process.env.REACT_APP_GOOGLE_CALLBACK ?? "http://localhost:8000/auth/google"
);

export const customHeaderSecret = "_this-Is-THAT-KEY-you-will-SWEat-tO-Get";
export const customHeaderKey = "x-custom-header";
