import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UtilityState {
  isLoading: boolean;
  isModalOpen: boolean;
}

const initialState: UtilityState = {
  isLoading: false,
  isModalOpen: false,
};

const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setModal(state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload;
    },
  },
});

export const { setLoading, setModal } = utilitySlice.actions;
export default utilitySlice.reducer;
