import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools"; // import utility to set light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  tab: {
    fontWeight: "semibold", // change the font weight
  },
  tabpanel: {
    // fontFamily: "mono", // change the font family
  },
});

// define a custom variant
const colorfulVariant = definePartsStyle((props) => {
  const primaryColor = "#1910e5"; // Define the primary color
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    tab: {
      borderColor: "none",
      bg: mode(`white`, `gray.700`)(props),
      borderTopRadius: "none",
      borderBottom: "none",
      fontWeight: "500",
      color: mode(`gray.600`, "white")(props),
      _selected: {
        bg: mode("#fff", "gray.800")(props),
        color: "primary",
        borderTopRadius: "none",
        fontWeight: "500",
        border: "none",
        borderBottom: "3px solid",
        borderColor: "primary",
        mb: "-2px",
      },
    },
    tablist: {
      borderBottom: "2px solid",
      borderColor: "secondary",
      h: {
        base: "unset",
        md: "60px",
      },
      bg: mode(`white`, `gray.700`)(props),
    },
    tabpanel: {},
  };
});

const variants = {
  primary: colorfulVariant,
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle, variants });
