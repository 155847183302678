import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { routes } from "./routes";
import { scrollToTop } from "../utils";

const AppRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path || Math.random() * 10000}
            path={route.path}
            element={route.element}
          >
            {route.children?.map((child) => (
              <Route
                key={child.path || Math.random() * 10000}
                path={child.path}
                element={child.element}
              >
                {child.children?.map((grandChild) => (
                  <Route
                    key={grandChild.path || Math.random() * 10000}
                    path={grandChild.path}
                    element={grandChild.element}
                  >
                    {" "}
                    {grandChild.children?.map((greatGrandChild) => (
                      <Route
                        key={greatGrandChild.path || Math.random() * 10000}
                        path={greatGrandChild.path}
                        element={greatGrandChild.element}
                      ></Route>
                    ))}
                  </Route>
                ))}{" "}
              </Route>
            ))}
          </Route>
        ))}
      </Routes>
    </>
  );
};

export default AppRoutes;
