import { createContext, ReactNode, useMemo, useState } from "react";

// Define the shape of the context's value
interface LearningContextType {
  isPlaying: boolean;
  togglePlaying: () => void;
  currentLesson: Lesson | null;
}

// Create the context with a default value (will be overridden by the provider)
export const LearningContext = createContext<LearningContextType>({
  isPlaying: false,
  togglePlaying: () => {
    return;
  },
  currentLesson: null,
});

// Create a provider component
export const LearningContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const togglePlaying = () => {
    setIsPlaying(!isPlaying);
  };

  const setPlaying = (playing: boolean) => setIsPlaying(playing);

  const value = useMemo(
    () => ({
      isPlaying,
      togglePlaying,
      setPlaying,
      currentLesson: null, // replace with actual current lesson state
    }),
    [isPlaying]
  );

  return (
    <LearningContext.Provider value={value}>
      {children}
    </LearningContext.Provider>
  );
};
