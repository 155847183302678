import { AxiosError } from "axios";
import { ApiError, ApiException } from "../types";

export interface AsyncState<T, E = unknown> {
  payload?: T;
  loading: boolean;
  errors?: E[];
}

export const retrieveErrorResponse = <
  T extends ApiException | ApiError[] = ApiException
>(
  e: unknown
): T | undefined => {
  if (isAxiosError<T>(e)) {
    return e?.response?.data as T;
  }
};

export const isAxiosError = <T>(e: unknown): e is AxiosError<T> => {
  return (e as AxiosError<T>).isAxiosError;
};

export const isValidFileSize = (file: File): boolean => {
  const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
  return file.size <= maxSize;
};

export const isValidFileType = (file: File): boolean => {
  const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
  return allowedTypes.includes(file.type);
};

export const currentSearchParams = () =>
  new URLSearchParams(window?.location?.search);
export const currentPathName = () => window?.location?.pathname;

export const slutText = (text: string) =>
  text.split("-").join("_").split(" ").join("-").toLowerCase();

export const unSlutText = (text: string) =>
  text.split("-").join(" ").split("_").join("-").toLowerCase();

export const formatDate = (date: string | Date): string =>
  new Date(date).toLocaleDateString();

export const scrollToSection = (elemetId: string) =>
  document.getElementById(elemetId)?.scrollIntoView({
    behavior: "smooth",
  });

export const validateEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
export const formatValueToThousand = (number: number, withPlus?: boolean) => {
  if (number >= 1000)
    return `${(number / 1000).toFixed(1)}${withPlus ? `k+` : "k"}`;
  return `${number}`;
};

export const getFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase();
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // for smooth scrolling
  });
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let debounceTimer: ReturnType<typeof setTimeout>;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

export const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export function timeAgo(date: Date | string): string {
  let d;
  if (typeof date === "string") {
    d = new Date(date);
  } else if (typeof date === "object") {
    d = date;
  } else {
    return "Invalid date";
  }
  const now = new Date();
  const seconds = Math.floor((now.getTime() - d.getTime()) / 1000);

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "week", seconds: 604800 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(seconds / interval.seconds);
    if (count > 0) {
      return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
}

export const sentimentMap: { [key: number]: string } = {
  0: "",
  0.5: "Very Poor",
  1: "Poor",
  1.5: "Below Average",
  2: "Average",
  2.5: "Fair",
  3: "Good",
  3.5: "Above Average",
  4: "Very Good",
  4.5: "Excellent",
  5: "Outstanding",
};

export const createQueryStringUrl = (params: {
  [key: string]: string | undefined;
}) => {
  const queryString = Object.entries(params)
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) =>
      value ? `${key}=${encodeURIComponent(value)}` : null
    )
    .join("&");

  return queryString;
};

export const hashValue = async (value: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(value); // Convert string to Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", data); // Hash the data
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // Convert byte array to hex string
  return hashHex;
};
