import { TSetPassword } from "../store/types";
import { PrincipalResponse } from "../types";
import { backend, endpoints } from "./config.api";

export const checkEmail = (email: string) =>
  backend.post<{
    message: string;
    email?: string;
    next: string;
  }>(endpoints.auth.checkEmail, {
    email,
  });

export const ResetUserPassword = (data: {
  email: string;
  password: string;
  passwordConfirm: string;
  otp: string;
}) =>
  backend.post<{
    message: string;
    email?: string;
    next: string;
    token?: string;
  }>(endpoints.auth.resetPassword, data);

export const forgotUserPassword = (email: string) =>
  backend.post<{
    message: string;
    email?: string;
    next: string;
  }>(endpoints.auth.forgetPassword, {
    email,
  });
export const otp = (email: string, otp: string) =>
  backend.post<{
    email: string;
    message: string;
    next: string;
  }>(endpoints.auth.verifyOtp, {
    email,
    otp,
  });

export const password = (email: string, password: string) =>
  backend.post<{
    message: string;
    email?: string;
    next: string;
    token: string;
  }>(endpoints.auth.password, {
    email,
    password,
  });

export const setPassword = (data: TSetPassword) =>
  backend.post<{ message: string; email?: string; next: string }>(
    endpoints.auth.setPassword,
    {
      ...data,
    }
  );

type TUpdateUserPassword = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};
export const updateUserPassword = (data: TUpdateUserPassword) =>
  backend.patch<{ message: string }>(endpoints.students.updatePassword, {
    ...data,
  });

type TUpdateUserInfo = {
  firstName?: string;
  lastName?: string;
  email?: string;
  tel?: string;
  role?: string;
  file?: string;
};
export const updateUserProfile = (data: FormData) =>
  backend.patch<PrincipalResponse>(endpoints.students.updateProfile, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const updateBio = (bio?: string, occupation?: string) =>
  backend.post<{ message: string; email?: string; next: string }>(
    endpoints.auth.setPassword,
    {
      bio,
      occupation,
    }
  );

export type TCompleteProfile = {
  email: string;
  firstName: string;
  lastName: string;
  tel: string;
};

export const completeProfile = (data: TCompleteProfile) =>
  backend.post<{
    message: string;
    email?: string;
    next: string;
    token: string;
  }>(endpoints.auth.completeProfile, data);

export const getEnrolPrograms = () =>
  backend.get(endpoints.students.getPrograms);

export const getMe = (token?: string) => {
  if (token) {
    return backend.get<PrincipalResponse>(endpoints.students.me, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  return backend.get<PrincipalResponse>(endpoints.students.me);
};

export const googleAuth = () =>
  backend.get<{
    token: string;
  }>(endpoints.auth.google);

export const addToWishlist = (id: string) =>
  backend.post<{
    wishlist: TrainingProgram[];
  }>(endpoints.students.wishlist(id));
export const removeToWishlist = (id: string) =>
  backend.delete<{
    wishlist: TrainingProgram[];
  }>(endpoints.students.wishlist(id));
export const getWishlist = () =>
  backend.get<{
    wishlist: TrainingProgram[];
  }>(endpoints.students.wishlist());

export const checkWishlist = (id: string) => {
  return backend.get<boolean>(endpoints.students.checkIfInWishlist(id));
};
