import { extendTheme, Input } from "@chakra-ui/react";
import { mode, StyleFunctionProps } from "@chakra-ui/theme-tools";

import { ButtonStyles as Button } from "./components/buttonStyles";
import { tabsTheme } from "./components/Tabstyles";
import progressTheme from "./components/ProgressStyles";
import customCheckboxStyles from "./components/CheckBoxStyles";
import CustomInput from "./components/inputStyles";
import ToastStyles from "./components/ToastStyles";
// import { BodyStyles as Styles } from './components/bodyStyles';
// import { Card } from './components/cardStyles';
// import { Box } from './components/boxStyles';

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
};
/* Pallete 1 */
const black = "#000000";

const lightDark = "#121318";

const white = "#ffffff";
// const whitish = "#F9FAFB";
const toryBlue = "#1944AB";
const silver = "#bfbfbf";
// const manatee = '#96939b';
// const dodgerBlue = '#1e90ff';
const mangoTango = "#fc814a";
// const denim = "#1071E5";
const error = "#ff686e";
const venetianRed = "#C90612ff";
const background = { _light: white, _dark: black };

// const config: ThemeConfig = {
//   initialColorMode: 'light',
//   useSystemColorMode: false,
// };

export const liahStudyTheme = extendTheme({
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        color: "default",
        bg: mode(background._light, background._dark)(props),
      },
    }),
  },
  fonts: {
    heading: "'Open Sans', sans-serif",
    body: "'montserrat', sans-serif",
  },
  colors: {
    primary: "#1910e5", //#210185 //#1071E5//#1910e5
    primay1: "#1071E5", //
    primaryHover: "#5951F6",
    robbenEggBlue: "#10DCE5", //
    primaryText: "gray.600",
    lightPrimary: "#210185",
    background: "#f0f2f5",
    secondary: "#EBEBFF",
    tertiary: silver,
    cardColor: lightDark,
    warning: mangoTango,
    danger: venetianRed,
    darken: black,
    sucess: "#23BD33",
    cambrigdeBlue: "#7FC6A4",
    nyanza: "##D6F8D6",
    main_color: {
      default: "#1071E5",
      100: "#F7FAFC",
      200: "#EDF2F7",
      300: "#E2E8F0",
      400: "#CBD5E0",
    },
    grey: {
      100: "#F7FAFC",
      200: "#EDF2F7",
      300: "#E2E8F0",
      400: "#CBD5E0",
      500: "#8C94A3", //#8C94A3 //#8C94A3 //#A0AEC0
      600: "#718096",
      700: "#4A5568",
      800: "#2D3748",
      900: "##1D2026",
    },

    azure: {
      DEFAULT: "#1B7AEE",
      100: "#041831",
      200: "#073063",
      300: "#0b4994",
      400: "#0f61c5",
      500: "#1b7aee",
      600: "#4995f1",
      700: "#76aff5",
      800: "#a4caf8",
      900: "#d1e4fc",
    },
    oxford_blue: {
      DEFAULT: "#04244B",
      100: "#01070f",
      200: "#020e1d",
      300: "#02152c",
      400: "#031c3a",
      500: "#04244b",
      600: "#084a9b",
      700: "#0c72ed",
      800: "#5aa0f6",
      900: "#add0fb",
    },
    polynesian_blue: {
      DEFAULT: "#0A4B99",
      100: "#020f1f",
      200: "#041e3d",
      300: "#062d5c",
      400: "#083c7b",
      500: "#0a4b99",
      600: "#0e6adb",
      700: "#3b8ef3",
      800: "#7db4f7",
      900: "#bed9fb",
    },
    yale_blue: {
      DEFAULT: "#083771",
      100: "#020b17",
      200: "#03162e",
      300: "#052245",
      400: "#062d5c",
      500: "#083771",
      600: "#0d5bbb",
      700: "#2681f0",
      800: "#6eabf5",
      900: "#b7d5fa",
    },
    jordy_blue: {
      DEFAULT: "#9FC7F8",
      100: "#06254c",
      200: "#0b4b98",
      300: "#1170e4",
      400: "#549bf2",
      500: "#9fc7f8",
      600: "#b3d3f9",
      700: "#c6defb",
      800: "#d9e9fc",
      900: "#ecf4fe",
    },
    rich_black: {
      DEFAULT: "#010913",
      100: "#000204",
      200: "#000408",
      300: "#01060c",
      400: "#010710",
      500: "#010913",
      600: "#063670",
      700: "#0b62cd",
      800: "#4594f5",
      900: "#a2cafa",
    },
    ruddy_blue: {
      DEFAULT: "#5CA0F3",
      100: "#051f3f",
      200: "#093e7d",
      300: "#0e5cbc",
      400: "#1f7cee",
      500: "#5ca0f3",
      600: "#7eb4f5",
      700: "#9ec7f8",
      800: "#bed9fa",
      900: "#dfecfd",
    },
    denim: {
      DEFAULT: "#0D61C8",
      100: "#031428",
      200: "#052751",
      300: "#083b79",
      400: "#0a4ea1",
      500: "#0d61c8",
      600: "#217ef1",
      700: "#589ef4",
      800: "#90bff8",
      900: "#c7dffb",
    },
  },
  breakpoints,
  components: {
    Button,
    Tabs: tabsTheme,
    Progress: progressTheme,
    Checkbox: customCheckboxStyles,
    Input: CustomInput,
    Toast: ToastStyles,
  },
});
