import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    border: "1px solid",
    borderColor: "gray.200",
    borderRadius: "md",
    boxShadow: "none",
    height: {
      base: "2.5rem",
      md: "2.5rem",
    },
    color: "gray.800",
    _disabled: {
      bg: "gray.200", // Change the background color when disabled
      cursor: "not-allowed", // Change the cursor style when disabled
      opacity: 0.5, // Adjust opacity for a faded effect
    },
    _focusVisible: {
      boxShadow: "none",
    },
    _active: {
      boxShadow: "none",
      outline: "none",
    },
    _focus: {
      boxShadow: "none",
      outline: "none",
    },
    _hover: {
      boxShadow: "none",
      outline: "none",
    },
  },
});

const CustomInput = defineMultiStyleConfig({ baseStyle });

export default CustomInput;
