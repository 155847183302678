import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiException, PrincipalResponse } from "../../../types";
import { AsyncState, retrieveErrorResponse } from "../../../utils";
import {
  getMyPrograms,
  getProgram,
  getPrograms,
} from "../../../api/programs.api";

type ProgramPayload = PrincipalResponse;

const initialState: AsyncState<ProgramPayload, ApiException> = {
  loading: false,
  payload: {},
};

// Creating an async thunk to fetch training programs
export const fetchTrainingPrograms = createAsyncThunk(
  "features/fetchTrainingPrograms",
  async (_, thunkApi) => {
    try {
      const programs = await getPrograms();
      return programs.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

//Creating an async thunk to fetch student enroll programs
export const fetchEnrollTrainingPrograms = createAsyncThunk(
  "features/fetchEnrollTrainingPrograms",
  async (_, thunkApi) => {
    try {
      const programs = await getMyPrograms();
      return programs.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchProgram = createAsyncThunk(
  "features/fetchPrograms",
  async (id: string, thunkApi) => {
    try {
      const program = await getProgram(id);
      return program.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const trainingProgramsSlice = createSlice({
  name: "trainingPrograms",
  initialState,
  reducers: {
    clearEnrollPrograms: (state) => {
      // state.payload = { ...state.payload, enrolledPrograms: undefined };
      state.payload = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrainingPrograms.fulfilled, (state, action) => {
        state.payload = { ...state.payload, programs: action.payload.programs };
        state.loading = false;
      })
      .addCase(fetchTrainingPrograms.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrainingPrograms.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(fetchEnrollTrainingPrograms.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.payload = { ...state.payload, enrolledPrograms: action.payload };
        state.loading = false;
      })
      .addCase(fetchEnrollTrainingPrograms.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEnrollTrainingPrograms.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(fetchProgram.fulfilled, (state, action) => {
        state.loading = false;
        const currInterest = state.payload?.interest;

        if (currInterest?.length) {
          //Check if th program is in the store just incase of re-render
          const programExits = currInterest.some(
            (elt) =>
              elt.title.toLowerCase() === action.payload.title.toLowerCase()
          );
          // If yes, do nothing
          if (programExits) {
            return state;
          }
          // If not, add it
          state.payload = {
            ...state.payload,
            interest: [...currInterest, action.payload],
          };
        } else {
          state.payload = {
            ...state.payload,
            interest: [{ ...action.payload }],
          };
        }
      })
      .addCase(fetchProgram.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchProgram.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      });
  },
});

export const programActions = {
  // ...trainingProgramsSlice.actions,
  fetchTrainingPrograms,
  fetchEnrollTrainingPrograms,
  fetchProgram,
};
export default trainingProgramsSlice.reducer;
