import ReactDom from "react-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Box, Flex, Spinner, useColorModeValue } from "@chakra-ui/react";
import { setLoading, setModal } from "../../store/features/slices/utility";
import { useEffect, useState } from "react";
import { CloseIcon } from "@chakra-ui/icons";

const BackDrop = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(setModal(false));
  };
  return (
    <Box
      className="backdrop"
      onClick={closeModal}
      h={"100vh"}
      w={"100vw"}
      position={"fixed"}
      top={"0"}
      left={"0"}
      bg={useColorModeValue("rgba(0,0,0,0.5)", "rgba(0,0,0,0.8)")}
      zIndex={"999"}
    />
  );
};

// const ModalContent = () => {
//   return (
//     <Box
//       className="animate__fadeIn animate__bounceOut"
//       id="modal"
//       position={"fixed"}
//       top={"10%"}
//       left={"50%"}
//       transform={"translate(-50%, -50%)"}
//       zIndex={"1000"}
//       bg={"none"}
//       borderRadius={"md"}
//       // boxShadow={"lg"}
//       p={4}
//       // w={"100%"}
//       // h={"100%"}
//       maxW={"fit-content"}
//       maxH={"fit-content"}
//       overflowY={"auto"}
//       // bg={useColorModeValue("rgba(0,0,0,0.5)", "rgba(0,0,0,0.8)")}
//     >
//       <Box
//         className="modal-content"
//         position={"relative"}
//         h={"100%"}
//         w={"100%"}
//       >
//         <Center>
//           <Flex
//             direction={"column"}
//             justifyContent={"center"}
//             alignItems={"center"}
//             w={"100%"}
//             h={"100%"}
//             gap={4}
//           >
//             <SearchBar
//               width={{
//                 base: "100%",
//                 md: "40vw",
//               }}
//               placeholder={"Type to search... "}
//               IconColor="#1071E5"
//             />
//             <Stack
//               w={{
//                 base: "100%",
//                 md: "40vw",
//               }}
//               maxH={{
//                 base: "50vh",
//                 md: "70vh",
//               }}
//               borderRadius={"md"}
//               p={4}
//               overflowY={"auto"}
//               bg={useColorModeValue("Background", "gray.700")}
//             >
//               <Box
//                 w={"100%"}
//                 h={"100%"}
//                 bg={useColorModeValue("gray.100", "gray.700")}
//                 borderRadius={"md"}
//                 cursor={"pointer"}
//               >
//                 fdsf
//               </Box>{" "}
//             </Stack>
//           </Flex>
//         </Center>
//       </Box>
//     </Box>
//   );
// };
const IsLoading = () => {
  const dispatch = useAppDispatch();
  const [close, setClose] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setClose(true);
    }, 40000);
  }, []);
  const handleClose = () => {
    dispatch(setModal(false));
    dispatch(setLoading(false));
  };
  return (
    <Flex
      className="animate__fadeIn animate__bounceOut"
      position={"fixed"}
      top={0}
      left={0}
      zIndex={"1000"}
      h="100vh"
      w="100vw"
      bg={"none"}
    >
      <Box m="auto">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        {close && (
          <CloseIcon
            pos={"absolute"}
            right={3}
            top={3}
            color={"primary"}
            cursor={"pointer"}
            onClick={() => handleClose()}
          />
        )}
      </Box>
    </Flex>
  );
};
const Modal = () => {
  const { isModalOpen, isLoading } = useAppSelector((state) => state.utility);

  const backdrop = document.getElementById("backdrop");
  const modal = document.getElementById("modal");

  return (
    <>
      {(isLoading || isModalOpen) &&
        backdrop &&
        ReactDom.createPortal(<BackDrop />, backdrop)}
      {/* {isModalOpen && modal && ReactDom.createPortal(<ModalContent />, modal)} */}
      {isLoading && modal && ReactDom.createPortal(<IsLoading />, modal)}
    </>
  );
};

export default Modal;
