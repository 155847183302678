import { backend, endpoints } from "./config.api";

export type TCategories = {
  id?: number;
  _id: number;
  name: string;
  title: string;
  description: string;
  primaryImage?: string;
  secondaryImage?: string;
  totalPrograms: number;
  totalStudents: number;
  averageRating: number;
}[];

export type TCategoryData = {
  name: string;
  programs: TrainingProgram[];
  tags: string[];
};

export const getAllCategories = () =>
  backend.get<{ categories: TCategories }>(endpoints.categoies.get);

export const getCategryTopPorgrams = (category: string) =>
  backend.get<TCategoryData>(
    endpoints.trainingPrograms.getCategryTopPorgram(category)
  );
