import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  ChangeEvent,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { userActions } from "../store/features/slices/user";
import { useColorModeValue, useToast } from "@chakra-ui/react";
import {
  createQueryStringUrl,
  currentPathName,
  currentSearchParams,
  emailValidation,
  slutText,
  unSlutText,
} from "../utils";
import { emailkey } from "../constants";
import {
  PossibleSortingOptionAllPrograms,
  possibleStatus,
  TCategoryData,
} from "../types";
import { filterPrograms } from "../api/programs.api";
import { programActions } from "../store/features/slices/trainingProgram";
import { updateUserPassword } from "../api/user.api";
import { TCategories } from "../api/category.api";
import { categoryAction } from "../store/features/slices/category";

export const useQueryParams = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return Object.fromEntries(query.entries());
};
export const usePrincipal = () => {
  const { loading, payload } = useAppSelector((state) => state.user);
  return { principal: payload, loading };
};

export const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return isOnline;
};
export const useOnChangeQueryParams = () => {
  const navigate = useNavigate();
  return (consumer?: (params: URLSearchParams) => void) => {
    const params = currentSearchParams();
    consumer?.(params);
    const arr: string[] = [];
    for (const [k, v] of params) {
      arr.push(`${k}=${v}`);
    }
    navigate(`${currentPathName()}?${arr.join("&")}`);
  };
};

export const useAssignAllQueryParamsToNextPath = () => {
  const queryString = useQueryParams();
  const queryStringArray = Object.keys(queryString).map(
    (key) => `${key}=${queryString[key]}`
  );
  const finalQueryString = queryStringArray.join("&");
  return { path: (path: string) => `${path}?${finalQueryString}` };
};

export const useGetInterestedProgramByTitleFromUrl = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const { programTitle, programId } = useParams<{
    programTitle: string;
    lessonTitle: string | undefined;
    programId: string;
  }>();

  const dispatch = useAppDispatch();
  const [insterestedProgram, setInteresetedProgram] =
    useState<TrainingProgram>();

  const { payload, loading } = useAppSelector(
    (state) => state.trainingPrograms
  );
  const getProgram = () => {
    const program = payload?.interest?.find(
      (elt) => elt.title.toLowerCase() === unSlutText(programTitle ?? "")
    );
    if (!program) {
      dispatch(programActions.fetchProgram(programId ?? ""))
        .unwrap()
        .then((res) => {
          setInteresetedProgram(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setInteresetedProgram(program);
    }
  };

  useEffect(() => {
    getProgram();
  }, [programTitle]);

  return {
    loading,
    program: insterestedProgram,
    programTitle,
  };
};
export const useGetProgramByTitleFromUrl = () => {
  const { programTitle } = useParams<{
    programTitle: string;
    lessonTitle: string | undefined;
  }>();
  const programExits = useAppSelector((state) => {
    const program = state.trainingPrograms?.payload?.programs?.all.find(
      (elt) => elt.title.toLowerCase() === unSlutText(programTitle ?? "")
      // programTitle?.toLowerCase().split("-").join(" ")
    );
    return program;
  });

  return {
    program: programExits,
    programTitle,
  };
};
export const useCalculateProgramStats = (data: TrainingProgram | undefined) => {
  const program =
    data ??
    ({
      _id: "",
      category: "",
      collaborators: [],
      courses: [],
      image: "",
      introVideo: "",
      price: {
        amount: 0,
        currency: "xaf",
      },
      tags: [],
      title: "",
      tutor: {
        email: "",
        firstName: "",
        lastName: "",
        socials: [],
        tel: "",
      },
      description: "",
      createdAt: "",
      updatedAt: "",
    } as TrainingProgram);

  const statsFunc = () => {
    let totalLessons = 0;
    let totalModules = 0;
    const courseDetails = program.courses.map((course) => {
      const courseModules = course.modules.length;
      const courseLessons = course.modules.reduce(
        (lessonCount, module) => lessonCount + module.lessons.length,
        0
      );

      totalModules += courseModules;
      totalLessons += courseLessons;

      return {
        title: course.title,
        totalModules: courseModules,
        totalLessons: courseLessons,
      };
    });

    const averageRating = program.data?.rating ?? 0;

    const numberOfStudents = program.data?.numOfStudents || 0;
    const numberOfReviews = program.data?.numberOfReviews || 0;

    return {
      totalLessons,
      totalModules,
      averageRating,
      numberOfStudents,
      numberOfReviews,
      courseDetails,
    };
  };

  const stats = useMemo(statsFunc, [program]);
  return stats;
};

export const useCalculateCourseStats = (data: Course[] | undefined) => {
  const courses =
    data ??
    ([
      {
        description: "",
        image: "",
        modules: [],
        title: "",
      },
    ] as Course[]);

  const statsFunc = () => {
    let totalLessons = 0;
    let totalModules = 0;
    const courseDetails = courses.map((course) => {
      const courseModules = course.modules.length;
      const courseLessons = course.modules.reduce(
        (lessonCount, module) => lessonCount + module.lessons.length,
        0
      );

      totalModules += courseModules;
      totalLessons += courseLessons;

      return {
        title: course.title,
        totalModules: courseModules,
        totalLessons: courseLessons,
      };
    });

    return {
      totalLessons,
      totalModules,
      courseDetails,
    };
  };

  const stats = useMemo(statsFunc, [data]);

  return stats;
};

export const useGetMyProgramByTitleFromUrl = () => {
  const { programTitle } = useParams<{
    programTitle: string;
    lessonTitle: string | undefined;
  }>();
  const [program, setProgram] = useState<{
    program:
      | {
          program: TrainingProgram;
          percentageComplete: number;
          currentCourse: string;
          currentModule: string;
          currentLesson: string;
          currentLessonTimeStamp: string;
        }
      | undefined;
    programTitle: string | undefined;
  }>({
    program: undefined,
    programTitle: undefined,
  });
  const [stats, setStats] = useState<{
    totalLessons: number;
    totalModules: number;
    averageRating: number;
    numberOfStudents: number;
    numberOfReviews: number;
    courseDetails: {
      title: string;
      totalModules: number;
      totalLessons: number;
    }[];
  }>();

  const { payload, loading } = useAppSelector(
    (state) => state.trainingPrograms
  );

  useEffect(() => {
    if (payload?.enrolledPrograms) {
      payload.enrolledPrograms.completed.map((comPro) => {
        if (
          comPro.program.title.toLowerCase() === unSlutText(programTitle ?? "")
        ) {
          setProgram({
            program: comPro,
            programTitle: programTitle,
          });
        }
      });
      payload.enrolledPrograms.current.map((currPro) => {
        if (
          currPro.program.title.toLowerCase() === unSlutText(programTitle ?? "")
        ) {
          setProgram({
            program: currPro,
            programTitle: programTitle,
          });
        }
      });
    }
  }, [payload?.enrolledPrograms]);

  return {
    ...program,
    loading,
    programStats: useCalculateProgramStats(program.program?.program),
  };
};

export const useHandleOtpInput = () => {
  const value1 = useRef<HTMLInputElement | null>(null);
  const value2 = useRef<HTMLInputElement | null>(null);
  const value3 = useRef<HTMLInputElement | null>(null);
  const value4 = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryString = useQueryParams();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // return;
    if (
      !value1.current ||
      !value1.current ||
      !value2.current ||
      !value3.current
    ) {
      toast({
        title: "Error",
        description: "Invalid OTP",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    dispatch(
      userActions.verifyOtp({
        email: localStorage.getItem(emailkey) ?? "",
        otp: `${value1.current?.value}${value2.current?.value}${value3.current?.value}${value4.current?.value}`,
      })
    )
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        toast({
          title: res.message,
          status: "success",
          duration: 5000,
          isClosable: false,
        });
        const next = queryString.next
          ? `${res.next}?next=${queryString.next}`
          : res.next;
        navigate(next);
      })
      .catch(() => {
        setIsLoading(false);
        toast({
          title: "Failed",
          description: "Invalid OTP",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return {
    value1,
    value2,
    value3,
    value4,
    onSubmit,
    isLoading,
  };
};

export const useGetCourse = () => {
  const { courseTitle } = useParams<{ courseTitle: string }>();
  const [course, setCourse] = useState<Course>();
  const { program } = useGetMyProgramByTitleFromUrl();
  // console.log(courseTitle);

  useEffect(() => {
    const course = program?.program.courses.find(
      (elt) => elt.title.toLowerCase() === unSlutText(courseTitle ?? "")
    );
    setCourse(course);
  }, [program]);
  return course;
};

export const useGetModule = () => {
  const { moduleTitle } = useParams<{
    moduleTitle: string;
  }>();
  const [module, setModule] = useState<Module>();
  const course = useGetCourse();

  useEffect(() => {
    const module = course?.modules.find(
      (elt) => elt.title.toLowerCase() === unSlutText(moduleTitle ?? "")
    );
    setModule(module);
  }, [course]);

  return module;
};

//Filter by search term and group results by category results should look like this [{category: "Web design", data: [{}, {}]}, {category: "Public Speaking", data: [{}, {}]}]
export const useFilterBySearchTerm = (searchTerm: string) => {
  const programs = useAppSelector(
    (state) => state.trainingPrograms.payload?.programs?.all
  );
  // Filter courses by search term
  const filteredPrograms = programs?.filter((program) => {
    return program.title.toLowerCase().includes(searchTerm.toLowerCase());
  });
  // Group by category
  const groupedPrograms = filteredPrograms?.reduce((acc, curr) => {
    const category = curr.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(curr);
    return acc;
  }, {} as { [key: string]: TrainingProgram[] });
  return groupedPrograms;
};

export const useDeviceWidth = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setDeviceWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth, window.innerHeight]);

  return deviceWidth;
};

export const useGetEnrollPrograms = () => {
  const [programs, setPrograms] = useState<{
    completed: {
      program: TrainingProgram;
      percentageComplete: number;
      currentCourse: string;
      currentModule: string;
      currentLesson: string;
      currentLessonTimeStamp: string;
    }[];
    current: {
      program: TrainingProgram;
      percentageComplete: number;
      currentCourse: string;
      currentModule: string;
      currentLesson: string;
      currentLessonTimeStamp: string;
    }[];
    wishlist: TrainingProgram[];
  }>();
  const { payload, loading } = useAppSelector(
    (state) => state.trainingPrograms
  );

  useEffect(() => {
    setPrograms(payload?.enrolledPrograms);
  }, [payload?.enrolledPrograms]);

  return {
    programs,
    loading,
  };
};

export const useGetAuthUser = () => {
  const [user, setUser] = useState<Student>();
  const { payload, loading } = useAppSelector((state) => state.user);
  useEffect(() => {
    setUser(payload?.user);
  }, [payload?.user]);
  return { user, loading };
};

export const useTheme = () => {
  const gray100Gray700 = useColorModeValue("gray.100", "gray.700");
  const gray600White = useColorModeValue("gray.600", "white");
  const whiteGray600 = useColorModeValue("#FFFFFF", "gray.600");
  const gray50gray500 = useColorModeValue("gray.50", "gray.500");
  const whiteGray700 = useColorModeValue("white", "gray.700");
  return {
    gray100Gray700,
    gray600White,
    whiteGray600,
    gray50gray500,
    whiteGray700,
  };
};

export const useFilter = () => {
  const [program, setPrograms] = useState<TrainingProgram[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [inputText, setInputText] = useState("");
  const [sort, setSort] = useState<PossibleSortingOptionAllPrograms | string>(
    "a-z"
  );
  const [rating, setRating] = useState<(string | number)[]>([]);
  const [category, setCategory] = useState<(string | number)[]>([]);
  const [price, setPrice] = useState<number | undefined>();
  const [minMaxPrice, setMinMaxPrice] = useState<
    | {
        min: number;
        max: number;
      }
    | undefined
  >();

  const [filterIsClear, setFilterIsClear] = useState(true);

  const page = currentPage.toString(); // Added current page to query params
  const limit = itemsPerPage.toString(); // Added items per page to query params
  const [priceType, setPriceType] = useState<(string | number)[]>([]);
  const [count, setCount] = useState(0);
  const [totalPrograms, setTotalPrograms] = useState(0);

  const handleInputTextChange = (value: string) => {
    setInputText(value);
  };
  const handleSortChange = (
    value: PossibleSortingOptionAllPrograms | string
  ) => {
    setSort(value);
  };
  const handleMinMaxChange = (data: { type: "min" | "max"; value: number }) => {
    setFilterIsClear(false);
    if (data.type === "min") {
      setMinMaxPrice((state) => {
        if (!state) {
          return {
            min: data.value,
            max: 0,
          };
        }
        return { ...state, min: data.value };
      });
      return;
    }
    setMinMaxPrice((state) => {
      if (!state) {
        return {
          max: data.value,
          min: 0,
        };
      }
      return { ...state, max: data.value };
    });
  };
  const handleRatingChange = (values: (string | number)[]) => {
    setRating(values);
    setFilterIsClear(false);
    // return;
  };
  const handleCategoryChange = (values: (string | number)[]) => {
    setCategory(values);
    setFilterIsClear(false);
  };

  const handlePriceChange = (value: number) => {
    // setPrice(parseInt(value));
    return;
  };
  const handlePriceTypeChange = (value: (string | number)[]) => {
    setPriceType(value);
    setFilterIsClear(false);
  };

  const clearFilters = () => {
    if (filterIsClear) return;
    setCategory([]);
    setPrice(undefined);
    setRating([]);
    setSort("a-z");
    setInputText("");
    setPriceType([]);
    setPrice(undefined);
    setFilterIsClear(true);
  };

  const sortPrograms = (programs: TrainingProgram[], sortOption: string) => {
    switch (sortOption) {
      case "newest":
        return programs.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      case "oldest":
        return programs.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      // case "most-popular":
      //   return programs.sort(
      //     (a, b) => b.data?.numOfStudents - a.data?.numOfStudents
      //   );
      // case "least-popular":
      //   return programs.sort(
      //     (a, b) => a.data.numOfStudents - b.data.numOfStudents
      //   );
      case "a-z":
        return programs.sort((a, b) => a.title.localeCompare(b.title));
      case "z-a":
        return programs.sort((a, b) => b.title.localeCompare(a.title));
      default:
        return programs;
    }
  };

  useEffect(() => {
    if (program.length > 0) {
      const sortedPrograms = sortPrograms([...program], sort);
      setPrograms(sortedPrograms);
    }
  }, [sort]);

  useEffect(() => {
    const catSelected = category.length;
    const ratingSelected = rating.length;
    const priceTypeSelected = priceType.length;
    setCount(catSelected + ratingSelected + priceTypeSelected);
  }, [category, rating, priceType]);

  // Fetch data when filters or pagination changes
  useEffect(() => {
    fetchData(); // Updated to trigger fetchData when page changes
  }, [category, minMaxPrice, inputText, rating, currentPage]); // Added currentPage and itemsPerPage

  const fetchData = async () => {
    const q = inputText ? inputText.trim() : undefined;
    const r = rating.length ? rating.toString() : undefined;
    const c = category.length ? category.toString() : undefined;
    const p = price ? price.toString() : undefined;
    const min = minMaxPrice ? minMaxPrice.min.toString() : undefined;
    const max = minMaxPrice ? minMaxPrice.max.toString() : undefined;
    const t = priceType.length ? priceType.toString() : undefined;

    const params = {
      q,
      r,
      c,
      p,
      min,
      max,
      t,
      page,
      limit,
    };

    const queryString = createQueryStringUrl(params);
    try {
      setIsLoading(true);

      const res = await filterPrograms(queryString);

      setPrograms(res.data.programs);
      setTotalPrograms(res.data.total);
    } catch (err) {
      // setError(err);
      setPrograms([]);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page); // New handler to change the current page
  };

  const handleItemsPerPageChange = (limit: number) => {
    setItemsPerPage(limit);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const totalPages = Math.ceil(totalPrograms / itemsPerPage); // Calculate total pages

  return {
    inputText,
    handleInputTextChange,
    sort,
    handleSortChange,
    category,
    handleCategoryChange,
    handleRatingChange,
    rating,
    clearFilters,
    count,
    price,
    priceType,
    handlePriceChange,
    handlePriceTypeChange,
    handleMinMaxChange,
    minMaxPrice,
    program,
    isLoading,
    currentPage,
    itemsPerPage,
    handlePageChange,
    handleItemsPerPageChange,
    totalPrograms,
    totalPages, // Return total pages
  };
};

export const useFilterMyPrograms = () => {
  const { payload, loading } = useAppSelector(
    (state) => state.trainingPrograms
  );
  const possibleSort = [
    {
      value: "title-asc",
      label: "Title ASC",
    },
    {
      value: "title-desc",
      label: "Title DESC",
    },
    {
      value: "completion-asc",
      label: "Completion ASC",
    },
    {
      value: "completion-desc",
      label: "Completion DESC",
    },
    {
      value: "date-asc",
      label: "Date ASC",
    },
    {
      value: "date-desc",
      label: "Date DESC",
    },
  ];
  const [filteredPrograms, setFilteredPrograms] = useState<
    {
      program: TrainingProgram;
      percentageComplete: number;
      currentCourse: string;
      currentModule: string;
      currentLesson: string;
      currentLessonTimeStamp: string;
    }[]
  >([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("title-asc");
  const [status, setStatus] = useState<string>("");
  const [tutor, setTutor] = useState("");
  const [total, setTotal] = useState(0);

  const handleSortChange = (value: string) => {
    setSort(value);
  };
  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
  };
  const handleStatusChange = (value: possibleStatus | string) => {
    setStatus(value);
  };
  const handleTutorChange = (value: string) => {
    setTutor(value);
  };

  useEffect(() => {
    const allPrograms = [
      ...(payload?.enrolledPrograms?.completed || []),
      ...(payload?.enrolledPrograms?.current || []),
    ];
    setTotal(allPrograms.length);

    let filtered = allPrograms;

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter((p) =>
        p.program.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by status
    if (status) {
      filtered = filtered.filter((p) => {
        if (status === "completed") {
          return payload?.enrolledPrograms?.completed.includes(p);
        } else if (status === "active") {
          return payload?.enrolledPrograms?.current.includes(p);
        }
        return true;
      });
    }

    // Filter by tutor
    // if (tutor) {
    //   filtered = filtered.filter((p) =>
    //     p.program.tutor.toLowerCase().includes(tutor.toLowerCase())
    //   );
    // }

    // Sort programs
    if (sort) {
      filtered = filtered.sort((a, b) => {
        switch (sort) {
          case "title-asc":
            return a.program.title.localeCompare(b.program.title);
          case "title-desc":
            return b.program.title.localeCompare(a.program.title);
          case "completion-asc":
            return a.percentageComplete - b.percentageComplete;
          case "completion-desc":
            return b.percentageComplete - a.percentageComplete;
          case "date-asc":
            return (
              new Date(a.program.createdAt).getTime() -
              new Date(b.program.createdAt).getTime()
            );
          case "date-desc":
            return (
              new Date(b.program.createdAt).getTime() -
              new Date(a.program.createdAt).getTime()
            );
          default:
            return 0;
        }
      });
    }

    const PossibleSortingOptionAllPrograms = [{}];

    setFilteredPrograms(filtered);
  }, [payload, searchTerm, sort, status, tutor]);

  return {
    filteredPrograms,
    handleSortChange,
    handleStatusChange,
    handleSearchTermChange,
    handleTutorChange,
    possibleSort,
    total,
    sort,
    searchTerm,
    status,
    loading,
  };
};

export const useFilterTutors = () => {
  const sortPossibiliteis = [
    {
      value: "name-asc",
      label: "Name ASC",
    },
    {
      value: "name-desc",
      label: "Name DESC",
    },
  ];
  const [tutors, setTutors] = useState<Tutor[]>([
    {
      firstName: "Steve Cooper",
      lastName: "Smith",
      email: "tutor1@example.com",
      photoURL: "",
      role: "Senior Developer",
      socials: {
        facebook: "facebook.com/stevecooper",
        twitter: "twitter.com/stevecooper",
        linkedin: "linkedin.com/in/stevecooper",
      },
      tel: "",
    },
    {
      firstName: "Steve Cooper",
      lastName: "Smith",
      email: "tutor1@example.com",
      photoURL: "",
      role: "Senior Developer",
      socials: {
        facebook: "facebook.com/stevecooper",
        twitter: "twitter.com/stevecooper",
        linkedin: "linkedin.com/in/stevecooper",
      },
      tel: "",
    },
    {
      firstName: "Steve Cooper",
      lastName: "Smith",
      email: "tutor1@example.com",
      photoURL: "",
      role: "Senior Developer",
      socials: {
        facebook: "facebook.com/stevecooper",
        twitter: "twitter.com/stevecooper",
        linkedin: "linkedin.com/in/stevecooper",
      },
      tel: "",
    },
  ]);
  const [tutor, setTutor] = useState("");
  const [sort, setSort] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState(0);

  const handleTutorChange = (value: string) => {
    setTutor(value);
  };
  const handleSearchTermChange = (value: string) => {
    setTutor(value);
  };

  const handleSortChange = (value: string) => {
    setSort(value);
  };

  return {
    tutors,
    tutor,
    loading,
    sort,
    total,
    handleSortChange,
    handleTutorChange,
    handleSearchTermChange,
    searchTerm,
    sortPossibiliteis,
  };
};

export const useCategotyList = () => {
  const [categories, setCategories] = useState<
    {
      label: string;
      value: string;
    }[]
  >();

  const { payload, loading } = useAppSelector((state) => state.category);

  useEffect(() => {
    if (payload) {
      const categories = payload?.categories?.map((category) => ({
        label: category.name,
        value: slutText(category.name),
      }));
      setCategories(categories ?? []);
    }
  }, [payload]);

  return { categories, loading };
};

export const useUserProfile = () => {
  type stateType = {
    firstName: string;
    lastName: string;
    email: string;
    photoURL: string;
    tel: string;
    role: string;
    currentPassword: string;
    confirmPassword: string;
    newPassword: string;
  };
  const reducer = (
    state: stateType,
    action: {
      key: keyof stateType | "bulk";
      value: string;
      data?: stateType | { [key: string]: string };
    }
  ) => {
    switch (action.key) {
      case "firstName":
        return {
          ...state,
          firstName: action.value,
        };
      case "lastName":
        return {
          ...state,
          lastName: action.value,
        };
      case "tel":
        return {
          ...state,
          tel: action.value,
        };
      case "email":
        return {
          ...state,
          email: action.value,
        };
      case "currentPassword":
        return {
          ...state,
          currentPassword: action.value,
        };
      case "newPassword":
        return {
          ...state,
          newPassword: action.value,
        };
      case "confirmPassword":
        return {
          ...state,
          confirmPassword: action.value,
        };
      case "photoURL":
        return {
          ...state,
          photoURL: action.value,
        };
      case "role":
        return {
          ...state,
          role: action.value,
        };
      case "bulk":
        return {
          ...state,
          ...action.data,
        };
      default:
        return state;
    }
  };
  const initial: stateType = {
    firstName: "",
    lastName: "",
    email: "",
    photoURL: "",
    tel: "",
    role: "",
    confirmPassword: "",
    newPassword: "",
    currentPassword: "",
  };
  const { user } = useGetAuthUser();
  const [file, setFile] = useState<File | null>(null); //handle profile update
  const [userData, SetUserData] = useReducer(reducer, initial);
  const [passwordIsUpdated, setPasswordIsUpdated] = useState(false);
  const [infoIsUpdated, setInfoIsUpdated] = useState(false);
  const [passwordIsLoading, setPasswordIsLoading] = useState(false);
  const [infoIsLoading, setInfoIsLoading] = useState(false);
  const [newData, setNewData] = useState<{ [key: string]: string }>({});
  const toast = useToast();
  const dispatch = useAppDispatch();

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
    if (e.target.type === "file") {
      try {
        setFile(e.target.files![0] ?? null);
        SetUserData({
          key: e.target.name as keyof stateType,
          value: e.target.files ? URL.createObjectURL(e.target.files[0]) : "",
        });
        setNewData((state) => {
          return { ...state, [e.target.name]: e.target.value };
        });
      } catch {
        return;
      }
    } else {
      SetUserData({
        key: e.target.name as keyof stateType,
        value: e.target.value,
      });
      setNewData((state) => {
        const { name, value } = e.target as {
          name: keyof Omit<
            stateType,
            "newPassword" | "currentPassword" | "confirmPassword"
          >;
          value: string;
        };
        if (value.length === 0 || user?.[name] === value) {
          // Create a copy of the state

          const { [name]: _, ...rest } = state; // Exclude the key you want to remove
          return rest; // Return the remaining state
        }
        return { ...state, [name]: value }; // Otherwise, update the state with the new value
      });
    }
  };

  const onSubmit = async (form: "password" | "info") => {
    if (form === "password") {
      if (!userData.currentPassword || userData.currentPassword.length < 8) {
        toast({
          title: "Provide a correct current password",
          status: "error",
          duration: 5000,
        });
        return;
      }
      if (userData.newPassword.length < 8) {
        toast({
          title: "Password must be at least 8 characters",
          status: "error",
          duration: 5000,
        });
        return;
      }
      if (userData.newPassword !== userData.confirmPassword) {
        toast({
          title: "Passwords do not match!",
          status: "error",
          duration: 5000,
        });
        return;
      }

      try {
        setPasswordIsLoading(true);
        const response = await updateUserPassword({
          currentPassword: userData.currentPassword,
          newPassword: userData.newPassword,
          confirmPassword: userData.confirmPassword, //passwordConfirm
        });
        setPasswordIsLoading(false);
        if (response.status === 200) {
          toast({
            title: "Password updated successfully!",
            status: "success",
            duration: 5000,
          });
          setPasswordIsUpdated(false);
          SetUserData({
            key: "bulk",
            value: "",
            data: {
              confirmPassword: "",
              newPassword: "",
              currentPassword: "",
            },
          });
        }
      } catch (error: any) {
        setPasswordIsLoading(false);
        toast({
          title: error.response.data.message ?? "Fail. Try again later",
          status: "error",
          duration: 5000,
        });
      }
    } else {
      if (
        userData.role &&
        userData.role.length > 0 &&
        userData.role.length < 3
      ) {
        toast({
          title: "Role must be at least 3 characters",
          status: "error",
          duration: 5000,
        });
        return;
      }
      if (!emailValidation.test(userData.email)) {
        toast({
          title: "Invalid email address",
          status: "error",
          duration: 5000,
        });
        return;
      }
      setInfoIsLoading(true);
      const form = new FormData();

      Object.keys(newData).forEach((key) => {
        if (key === "photoURL" || key === "bulk") return;
        form.append(key, newData[key].trim());
      });

      if (file) form.append("photoFile", file);
      dispatch(userActions.updateProfile(form))
        .unwrap()
        .then(() => {
          setInfoIsLoading(false);
          setInfoIsUpdated(false);
          toast({
            title: "Profile updated successfully!",
            status: "success",
            duration: 5000,
          });
        })
        .catch(() => {
          setInfoIsLoading(false);
        });
    }
  };

  useEffect(() => {
    SetUserData({
      key: "bulk",
      value: "",
      data: {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
        photoURL: user?.photoURL || "",
        tel: user?.tel || "",
        role: user?.role || "",
        confirmPassword: "",
        newPassword: "",
        currentPassword: "",
      },
    });
  }, [user]);

  useEffect(() => {
    if (Object.keys(newData).length == 0) {
      setInfoIsUpdated(false);
      setPasswordIsUpdated(false);
      return;
    }
    if (
      (Object.keys(newData).includes("currentPassword") &&
        newData.currentPassword.length > 0) ||
      (Object.keys(newData).includes("newPassword") &&
        newData.newPassword.length > 0) ||
      (Object.keys(newData).includes("confirmPassword") &&
        newData.confirmPassword.length > 0)
    ) {
      setPasswordIsUpdated(true);
    } else {
      setPasswordIsUpdated(false);
    }

    if (
      Object.keys(newData).includes("firstName") ||
      Object.keys(newData).includes("lastName") ||
      Object.keys(newData).includes("role") ||
      Object.keys(newData).includes("tel") ||
      Object.keys(newData).includes("email") ||
      Object.keys(newData).includes("photoURL")
    ) {
      setInfoIsUpdated(true);
    }
  }, [newData]);

  return {
    onChange,
    userData,
    onSubmit,
    passwordIsLoading,
    infoIsLoading,
    passwordIsUpdated,
    infoIsUpdated,
  };
};

export const useCategories = () => {
  const { loading, payload, errors } = useAppSelector(
    (state) => state.category
  );
  const [categories, setCategories] = useState<TCategories>();

  useEffect(() => {
    if (loading) return;
    setCategories(payload?.categories);
  }, [payload?.categories]);

  return { categories, loading, errors };
};

export const useCategory = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { loading, payload } = useAppSelector((state) => state.category);
  const [category, setCategory] = useState<TCategoryData>();
  useEffect(() => {
    if (!params.categoryTitle) return;
    if (payload?.[params.categoryTitle]) {
      setCategory(payload?.[params.categoryTitle]);
      return;
    }
    dispatch(categoryAction.addCategoryData(params.categoryTitle ?? ""));
  }, [params.categoryTitle]);

  useEffect(() => {
    if (!params.categoryTitle) return setCategory(undefined);
    console.log(payload?.[params.categoryTitle]);
    if (!payload) return;
    setCategory(payload[params.categoryTitle]);
  }, [payload]);

  return { category, loading };
};
