import { JwtPayload, jwtDecode } from "jwt-decode";

function isTokenExpired(token: string): boolean {
  const decoded = jwtDecode<JwtPayload & { user: Student }>(token);
  const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
  if (!decoded.exp) {
    return true;
  }
  return decoded.exp < currentTime;
}

export default isTokenExpired;
