import { createListenerMiddleware, type AsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { userActions } from "../../slices/user";
import { principalStorageKey } from "../../../../constants";

const listenerMiddleware = createListenerMiddleware();
const combinedUserActions = [
  ...Object.values(userActions).map(
    (a) => (a as unknown as AsyncThunk<unknown, unknown, object>).fulfilled ?? a
  ),
];

combinedUserActions.forEach((cAction) => {
  listenerMiddleware.startListening({
    actionCreator: cAction,
    effect: (action, listenerApi) => {
      if (cAction.type === action.type) {
        const currentPrincipalAsString = JSON.stringify(
          (listenerApi.getState() as unknown as RootState).user
        );
        window.localStorage.setItem(
          principalStorageKey,
          currentPrincipalAsString
        );
      }
    },
  });
});

export { listenerMiddleware };
