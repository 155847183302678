import { Suspense, useEffect, useRef } from "react";

import { Box, ChakraProvider, useToast } from "@chakra-ui/react";
import { liahStudyTheme } from "./assets/styles/theme";
// import { ColorModeSwitcher } from "./ColorModeSwitcher";
import Modal from "./components/Modal/Modal";
import { useAppDispatch } from "./store/hooks";
import isTokenExpired from "./utils/verifyTokenValidity";
import { userActions } from "./store/features/slices/user";
import { programActions } from "./store/features/slices/trainingProgram";
import { principalStorageKey, tokenkey } from "./constants";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import Loading from "./components/Loaders/Loading";
import { categoryAction } from "./store/features/slices/category";
import OnlineStatusNotifier from "./components/notification/OnlineStatusNotifier";

function App() {
  const dispatch = useAppDispatch();
  const userLoadRef = useRef(false);

  useEffect(() => {
    //Check if the user token is valid else clear the user from the redux storage
    const tkn = localStorage.getItem(tokenkey);
    if (typeof tkn == "string") {
      const tokenIsExpired = isTokenExpired(tkn);

      if (tokenIsExpired) {
        dispatch(userActions.signout());
        return;
      }
    } else {
      dispatch(userActions.signout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(programActions.fetchTrainingPrograms());
    dispatch(categoryAction.fetchAllCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storeUser = localStorage.getItem(principalStorageKey);
    if (!userLoadRef.current) {
      if (storeUser) {
        dispatch(
          userActions.loadUserFromStorage(JSON.parse(storeUser)?.payload)
        );
      }
      dispatch(userActions.getUserInfo({}));

      userLoadRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChakraProvider theme={liahStudyTheme}>
      <OnlineStatusNotifier />
      <Box pos={"relative"} fontFamily="Montserrat">
        <Modal />
          <Suspense fallback={<Loading />}>
            <Router>
              <AppRoutes />
            </Router>
          </Suspense>
      </Box>
    </ChakraProvider>
  );
}

export default App;
