import { configureStore } from "@reduxjs/toolkit";
import user from "./features/slices/user";
import trainingPrograms from "./features/slices/trainingProgram";
import utility from "./features/slices/utility";
import category from "./features/slices/category";
import middlewares from "./features/middleware";

// Define the AppDispatch type
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Create the store using the root reducer
export const store = configureStore({
  reducer: {
    user,
    trainingPrograms,
    utility,
    category,
  },
  middleware: (defaultMiddleware) => defaultMiddleware().concat(middlewares),
});

export default store;
