import { mode } from "@chakra-ui/theme-tools";

export const ButtonStyles = {
  //  style the base or default style
  baseStyle: {
    fontSize: "sm",
    _hover: {
      _disabled: {
        _hover: {
          bg: "#5951F6",
        },
      },
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: (props: any) => ({
      bg: "primary",
      fontSize: "sm",
      color: "white",
      _hover: {
        bg: mode("#5951F6", "#0b2050")(props),
        boxShadow: "md",
      },
      // _disabled: {
      //   _hover: {
      //     bg: '#5951F6',
      //   },
      // },
      borderRadius: "5px",
    }),
    primaryOutline: (props: any) => ({
      bg: "transparent",
      border: "1px solid",
      color: mode("primary", "white")(props),
      _hover: {
        // transform: "scale(1.02)",
        border: "1px solid",
        boxShadow: "md",
      },
    }),
    primaryGhost: (props: any) => ({
      bg: "transparent",
      color: mode("primary", "white")(props),
      _hover: {
        // transform: "scale(1.02)",
        // boxShadow: "md",
      },
      _active: {
        bg: "gray.100",
      },
    }),
    secondary: (props: any) => ({
      bg: "secondary",
      color: "primary",
      _hover: {
        bg: "primary",
        color: "white",
        boxShadow: "md",
        transition: "linear 0.5 all ",
      },
      fontWeight: 400,
    }),
    theOutline: (props: any) => ({
      bg: "transparent",
      width: "max-content",
      border: "1px solid gainsboro",
      _hover: {
        backgroundColor: "#FAFBFC",
      },
    }),
    tertiary: () => ({
      bg: "transparent",
      border: "1px solid #FFFFFF",
      color: "#FFFFFF",
      textTransform: "uppercase",
      _hover: {
        transform: "scale(1.02)",
        padding: "3px",
        border: "1px solid",
        boxShadow: "md",
      },
    }),
    danger: (props: any) => ({
      bg: "#e91e63",
      color: "white",
      _hover: {
        bg: mode("#9d144f", "#e91e63")(props),
        boxShadow: "md",
      },
    }),
    special1: (props: any) => ({
      bg: "transparent",
      border: "1px solid gainsboro",
      color: mode("black", "white")(props),
      _hover: {
        transform: "scale(1.02)",
        border: "2px solid",
        boxShadow: "md",
      },
    }),
  },

  //  default values for `size` and `variant`
  defaultProps: {},
};
