import { defineStyleConfig } from "@chakra-ui/react";

const ToastStyles = defineStyleConfig({
  variants: {
    warning: {
      container: {
        bg: "#feb300",
        color: "white",
      },
    },
  },
});

export default ToastStyles;
