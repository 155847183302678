import { defineStyleConfig } from "@chakra-ui/react";

const progressTheme = defineStyleConfig({
  baseStyle: {
    filledTrack: {
      bg: "primary", // Use the primary color
    },
    track: {
      bg: "secondary",
    },
  },
  sizes: {
    sm: {
      track: {
        h: "4px",
      },
    },
    md: {
      track: {
        h: "6px",
      },
    },
    lg: {
      track: {
        h: "8px",
      },
    },
  },
  defaultProps: {
    size: "md",
    colorScheme: "primary", // Default color scheme
  },
});

export default progressTheme;
