import { mode } from "@chakra-ui/theme-tools";
const customCheckboxStyles = {
  baseStyle: (props: any) => ({
    control: {
      bg: "gray.200",
      borderColor: "gray.400",
      h: "20px",
      w: "20px",
      _checked: {
        bg: mode("primary", "blue.300")(props),
        borderColor: mode("primary", "blue.300")(props),
        color: "white",
        _hover: {
          bg: mode("primaryHover", "blue.400")(props),
          borderColor: mode("blue.600", "blue.400")(props),
        },
        _disabled: {
          bg: mode("gray.300", "gray.700")(props),
          borderColor: mode("gray.300", "gray.700")(props),
        },
      },
      _hover: {
        borderColor: "gray.500",
      },
      _disabled: {
        bg: "gray.100",
        borderColor: "gray.300",
      },
    },
    label: {
      _disabled: {
        color: "gray.500",
      },
    },
  }),
};

export default customCheckboxStyles;
