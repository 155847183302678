import { PrincipalResponse } from "../types";
import { backend, endpoints } from "./config.api";

export const getPrograms = () =>
  backend.get<PrincipalResponse>(endpoints.trainingPrograms.root);
export const getMyPrograms = () => {
  return backend.get<{
    completed: {
      program: TrainingProgram;
      percentageComplete: number;
      currentCourse: string;
      currentModule: string;
      currentLesson: string;
      currentLessonTimeStamp: string;
    }[];
    current: {
      program: TrainingProgram;
      percentageComplete: number;
      currentCourse: string;
      currentModule: string;
      currentLesson: string;
      currentLessonTimeStamp: string;
    }[];
    wishlist: TrainingProgram[];
  }>(endpoints.students.getPrograms);
};

export const getProgram = (id: string) =>
  backend.get<TrainingProgram>(endpoints.trainingPrograms.get(id));

export const filterPrograms = (queryString: string) =>
  backend.get<{
    programs: TrainingProgram[];
    total: number;
  }>(endpoints.trainingPrograms.filter(queryString));

export const checkEnroll = (id: string) =>
  backend.get<boolean>(endpoints.students.checkEnroll(id));
