import { useEffect, useRef, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useOnlineStatus } from '../../hooks';

const OnlineStatusNotifier = () => {
  const isOnline = useOnlineStatus();
  const toast = useToast();
  const [hasBeenOffline, setHasBeenOffline] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    // Skip the first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!isOnline) {
      setHasBeenOffline(true);
      toast({
        title: "You are offline",
        description: "Check your internet connection.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (hasBeenOffline) {
      toast({
        title: "You are back online",
        description: "Your internet connection is restored.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setHasBeenOffline(false); // Reset the offline status
    }
  }, [isOnline, hasBeenOffline, toast]);

  return null;
};

export default OnlineStatusNotifier;
