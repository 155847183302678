import axios from "axios";
import {
  currentBackend,
  customHeaderKey,
  customHeaderSecret,
  emailkey,
  tokenkey,
} from "../constants";
import { hashValue } from "../utils";

let hash: string | null = null;
async function initialize() {
  const secret =
    process.env.REACT_APP_CUSTOM_HEADER_SECRET ?? customHeaderSecret;
  hash = await hashValue(secret);
}

initialize();

export const backend = axios.create({
  baseURL: currentBackend,
});

backend.interceptors.request.use((req) => {
  req.headers[customHeaderKey] = hash;
  req.headers["Authorization"] = "Bearer " + localStorage.getItem(tokenkey);
  return req;
});

backend.interceptors.response.use((res) => {
  const token = res.data.token as string;
  const email = res.data.email as string;
  if (email) {
    localStorage.setItem(emailkey, email);
  }
  if (token) {
    localStorage.setItem(tokenkey, token);
  }
  return res;
});

export enum Env {
  dev = "http://localhost:8000",
  prod = "https://liah-study-thebackend.onrender.com",
}

export const endpoints = {
  auth: {
    signin: "/auth/student/signin",
    signup: "/auth/student/signup",
    checkEmail: "/auth/student/check-email",
    password: "/auth/student/signin",
    setPassword: "/auth/student/set-password",
    verifyOtp: "/auth/student/verify-otp",
    forgetPassword: "/auth/student/forgot-password",
    resetPassword: "/auth/student/reset-password",
    completeProfile: "/auth/student/complete-profile",
    root: "/auth/student",
    google: "/auth/student/google",
    // logout: "/auth/logout",
    // googleLogin: "/auth/login/social/google",
    // googleRegister: "/auth/register/social/google",
  },
  students: {
    me: "/students/me",
    getPrograms: "/students/training-programs",
    enroll: (id: string) => "/students/training-program/enroll/" + id,
    checkEnroll: (id: string) => "/students/training-program/isenrolled/" + id,
    getEnrolled: "/students/training-programs/enrolled",
    unEnroll: (id: string) => "/students/training-programs/" + id,
    wishlist: (id?: string) =>
      id
        ? "/students/training-program/wishlist/" + id
        : "/students/training-program/wishlist",
    updateProfile: "/students/update/profile",
    checkIfInWishlist: (id: string) =>
      "/students/training-program/wishlist/check/" + id,
    // updateCountryCallingCode: "/students/update/country-calling-code",
    // updateTel: "/students/update/tel",
    // updatePhoto: "/students/update/photo",
    // updateFirstName: "/students/update/first-name",
    updatePassword: "/students/update/password",
    root: "/students",
  },
  globalSearch: "/global-search",
  trainingPrograms: {
    root: "/training-program",
    get: (id: string) => "/training-program/" + id,
    filter: (queryString: string) => `/training-program/filter?${queryString}`,
    enroll: "/training-program/enroll",
    getEnrolled: "/training-program/enrolled",
    getCategryTopPorgram: (category: string) =>
      `/training-program/category/${category}`,
  },
  categoies: {
    root: "/category",
    get: "/category",
  },
};
