import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiException, CategoryPayload } from "../../../types";
import { AsyncState, retrieveErrorResponse, slutText } from "../../../utils";
import {
  getAllCategories,
  getCategryTopPorgrams,
} from "../../../api/category.api";

export const fetchAllCategories = createAsyncThunk(
  "features/fetchAllCategories",
  async (_, thunkApi) => {
    try {
      const programs = await getAllCategories();
      return programs.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addCategoryData = createAsyncThunk(
  "features/category-search",
  async (category: string, thunkApi) => {
    try {
      const info = await getCategryTopPorgrams(category);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const initialState: AsyncState<CategoryPayload, ApiException> = {
  loading: false,
  payload: {},
};
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCategories.fulfilled, (state, action) => {
        state.payload = {
          ...state.payload,
          categories: action.payload.categories,
        };
        state.loading = false;
      })
      .addCase(fetchAllCategories.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      })
      .addCase(addCategoryData.fulfilled, (state, action) => {
        const { programs, tags, name } = action.payload;
        state.payload = {
          ...state.payload,
          [slutText(name)]: {
            programs,
            tags,
          },
        };
        state.loading = false;
      })
      .addCase(addCategoryData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCategoryData.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
        state.loading = false;
      });
  },
});

export const categoryAction = {
  fetchAllCategories,
  addCategoryData,
};

export default categorySlice.reducer;
